<style lang="scss">
 .close-form{
        cursor: pointer;
        float: right;
        i{
            font-size:20px
        }
    }
#modal-app-menu {

  .modal-dialog {
    position: relative;
    width: auto;
    margin: 0 !important;
    margin-top: 44.5px !important;
  }

  .modal-body{
      background: #e6e9ec;
  }
  
  .card {
   
    background: white;
    text-align: center;
    height: 160px !important;
    // width: 160px !important;
    cursor: pointer;
   

    .menu {
      margin-top: 15px;
      i {
        color: #337ab7;
        font-size: 40px;
        margin-bottom: 15px;
      }
      h6 {
        font-size: 14px;
        text-transform: uppercase;
         color: #337ab7;
      }
  
    }

    &:hover {
      background: #337ab7;

      .menu {
       
        i {
          color: #fff;
        
        }
      
        h6{
          color: #fff;
        }
      }
    }
  }
}

#modal-app-menu___BV_modal_content_ {
  border-radius: 0;
  height: calc(100vh - 44.5px);
  width: 100vw;
}
</style>
<template>
  <b-modal
    class="aaa"
    hide-header
    hide-footer
    style="margin-top: 100px;width:1000px"
    ref="modal-app-menu"
    id="modal-app-menu"

   
  >
    <div class="close-form" @click="hideModal()">
        <i class="fa fa-times"></i>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-12 offset-lg-3">
        <div class="row">
            
          <div
          style="padding:5px"
            class="col-lg-3 col-md-4"
            v-for="(menuItem, index) in getAppMenu"
            :key="index"
          >
            <div class="card" @click="redirectTOUrl(menuItem.url)" >
              <div class="card-body">
                <div class="menu">
                 
                    <i :class="menuItem.logo" aria-hidden="true"></i>
                    <h6>{{ menuItem.display_name }}</h6>
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { FETCH_APP_MENU } from '@/core/services/store/actions.type';
export default {
  data() {
    return {
        width: window.innerWidth,
     
    };
  },

  computed: {
    ...mapGetters(["getAppMenu"]),
  },

  mounted() {
      this.$store.dispatch(FETCH_APP_MENU)
   
  },

  methods: {
    hideModal() {
      this.$refs["modal-app-menu"].hide();
    },
    redirectTOUrl(url){
       window.location.href =url;
    }
  },
};
</script>
