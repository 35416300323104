<template>
  <ul class="menu-nav">
     <router-link
      to="/dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text">Dashboard</span>
        </a>
      </li>
    </router-link>
    <li
      class="menu-section"
      v-if="checkPermissionForMenuHeader(permissions.you, getUserPermission)"
    >
      <h4 class="menu-text">You</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

   
    <router-link
      v-if="
        checkPermissionForMenu(
          'access_to_own_attendance_records',
          getUserPermission
        )
      "
      :to="{
        name: 'employee.attendance.statements.month',
        params: { year: year, month: month },
      }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon icon-flat flaticon-stopwatch"></i>
          <span class="menu-text">Attendance </span>
        </a>
      </li>
    </router-link>

    <router-link
      v-if="
        checkPermissionForMenu('access_to_own_leaves_list', getUserPermission)
      "
      :to="{ name: 'leave.employee' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-calendar-with-a-clock-time-tools"></i>
          <span class="menu-text">Leaves</span>
        </a>
      </li>
    </router-link>

    <li class="menu-section"  v-if="checkPermissionForMenuHeader(permissions.company, getUserPermission)">
      <h4 class="menu-text">Company</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link
      v-if="
        checkPermissionForMenu(
          'access_to_all_employees_profile_data',
          getUserPermission
        )
      "
      :to="{ name: 'employees.list' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-users-1"></i>
          <span class="menu-text">Employees </span>
        </a>
      </li>
    </router-link>

    <router-link
      v-if="checkPermissionForMenu('access_to_assign_shift', getUserPermission)"
      :to="{ name: 'assign.shift.list' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-calendar-2"></i>
          <span class="menu-text">Shifts</span>
        </a>
      </li>
    </router-link>

    <router-link
      v-if="
        checkPermissionForMenu('access_to_all_leaves_list', getUserPermission)
      "
      :to="{ name: 'leave.all' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-calendar-with-a-clock-time-tools"></i>
          <span class="menu-text">Leaves</span>
        </a>
      </li>
    </router-link>

    <router-link
      v-if="
        checkPermissionForMenu(
          'access_to_all_employees_attendance_records',
          getUserPermission
        )
      "
      :to="{ name: 'all.attendance', params: { year: year, month: month } }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon icon-flat flaticon-stopwatch"></i>
          <span class="menu-text">Attendances</span>
        </a>
      </li>
    </router-link>

    <li class="menu-section"  v-if="checkPermissionForMenuHeader(permissions.payroll, getUserPermission)">
      <h4 class="menu-text">Payroll</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link
      v-if="checkPermissionForMenu('access_to_salary_heads', getUserPermission)"
      :to="{ name: 'payroll.salary.heads' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-list"></i>
          <span class="menu-text">Salary Heads</span>
        </a>
      </li>
    </router-link>

    <li class="menu-section"  v-if="checkPermissionForMenuHeader(permissions.settings, getUserPermission)" >
      <h4 class="menu-text">Settings</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link
      v-if="checkPermissionForMenu('access_to_settings', getUserPermission)"
      :to="{ name: 'settings' }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-settings"></i>
          <span class="menu-text">Settings</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import lodash from "lodash";
export default {
  name: "KTMenu",
  data() {
    return {
      year: moment(String(new Date())).format("YYYY"),
      month: moment(String(new Date())).format("MM"),
      permissions: {
        you: ["access_to_own_attendance_records", "access_to_own_leaves_list"],
        company: [
          "access_to_all_employees_profile_data",
          "access_to_assign_shift",
          "access_to_all_leaves_list",
          "access_to_all_employees_attendance_records",
        ],
        payroll: ["access_to_salary_heads"],
        settings: ["access_to_settings"],
      },
    };
  },

  computed: {
    ...mapGetters(["getUserPermission"]),
  },

  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    checkPermissionForMenu(permission, permissions) {
      var flag = false;
      lodash.filter(permissions, function (o) {
        if (o == permission) {
          flag = true;
          return flag;
        }
        return flag;
      });
      return flag;
    },
    checkPermissionForMenuHeader(cPermissions, permissions) {

      let result = permissions.filter(o1 => cPermissions.some(o2 => o1 === o2));
      return result.length>0;
    },
  },
};
</script>
