
<template>
  <div class="topbar-item">
    <modal-app-menu></modal-app-menu>
    <div
      class="w-auto btn-clean d-flex align-items-center btn-lg px-2" id="app-menu" v-b-modal.modal-app-menu
    >
      <span><i class="flat-icon flaticon2-grids"></i> App Menu </span>
    </div>



    
  </div>
</template>

<style lang="scss" scoped>

#app-menu {
    margin-right: 10px;

    border-radius: 0;
    cursor: pointer;
    &:hover{
        background-color: #3f4f62;
        border-radius: 0  !important;
    }
  span{
      color:#fff;
      font-size: 14px;
       i{
           font-size: 14px;
       }
  }
}
</style>

<script>
import modalAppMenu from '@/includes/extras/AppMenu/Menu';
export default {
    

    components:{
        'modal-app-menu':modalAppMenu
    },
 
}
</script>


