<template>
  <!-- begin:: Header Topbar -->
  <div class="topbar">

   <KTAppMenu></KTAppMenu>
       <KTQuickUser></KTQuickUser>
 
    <!--end: User Bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import KTQuickUser from "@/includes/extras/offcanvas/QuickUser.vue";
import KTAppMenu from "@/includes/extras/AppMenu"
// import i18nService from "@/core/services/i18n.service.js";

export default {
  name: "KTTopbar",
  components: {
    KTQuickUser,
    KTAppMenu
  }
};
</script>
