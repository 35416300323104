var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-architecture-and-city"}),_c('span',{staticClass:"menu-text"},[_vm._v("Dashboard")])])])]}}])}),(_vm.checkPermissionForMenuHeader(_vm.permissions.you, _vm.getUserPermission))?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v("You")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),(
      _vm.checkPermissionForMenu(
        'access_to_own_attendance_records',
        _vm.getUserPermission
      )
    )?_c('router-link',{attrs:{"to":{
      name: 'employee.attendance.statements.month',
      params: { year: _vm.year, month: _vm.month },
    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var href = ref.href;
    var navigate = ref.navigate;
    var isActive = ref.isActive;
    var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon icon-flat flaticon-stopwatch"}),_c('span',{staticClass:"menu-text"},[_vm._v("Attendance ")])])])]}}],null,false,1601796123)}):_vm._e(),(
      _vm.checkPermissionForMenu('access_to_own_leaves_list', _vm.getUserPermission)
    )?_c('router-link',{attrs:{"to":{ name: 'leave.employee' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var href = ref.href;
    var navigate = ref.navigate;
    var isActive = ref.isActive;
    var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-calendar-with-a-clock-time-tools"}),_c('span',{staticClass:"menu-text"},[_vm._v("Leaves")])])])]}}],null,false,2344538550)}):_vm._e(),(_vm.checkPermissionForMenuHeader(_vm.permissions.company, _vm.getUserPermission))?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v("Company")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),(
      _vm.checkPermissionForMenu(
        'access_to_all_employees_profile_data',
        _vm.getUserPermission
      )
    )?_c('router-link',{attrs:{"to":{ name: 'employees.list' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var href = ref.href;
    var navigate = ref.navigate;
    var isActive = ref.isActive;
    var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-users-1"}),_c('span',{staticClass:"menu-text"},[_vm._v("Employees ")])])])]}}],null,false,1161561435)}):_vm._e(),(_vm.checkPermissionForMenu('access_to_assign_shift', _vm.getUserPermission))?_c('router-link',{attrs:{"to":{ name: 'assign.shift.list' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-calendar-2"}),_c('span',{staticClass:"menu-text"},[_vm._v("Shifts")])])])]}}],null,false,599983370)}):_vm._e(),(
      _vm.checkPermissionForMenu('access_to_all_leaves_list', _vm.getUserPermission)
    )?_c('router-link',{attrs:{"to":{ name: 'leave.all' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var href = ref.href;
    var navigate = ref.navigate;
    var isActive = ref.isActive;
    var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-calendar-with-a-clock-time-tools"}),_c('span',{staticClass:"menu-text"},[_vm._v("Leaves")])])])]}}],null,false,2344538550)}):_vm._e(),(
      _vm.checkPermissionForMenu(
        'access_to_all_employees_attendance_records',
        _vm.getUserPermission
      )
    )?_c('router-link',{attrs:{"to":{ name: 'all.attendance', params: { year: _vm.year, month: _vm.month } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var href = ref.href;
    var navigate = ref.navigate;
    var isActive = ref.isActive;
    var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon icon-flat flaticon-stopwatch"}),_c('span',{staticClass:"menu-text"},[_vm._v("Attendances")])])])]}}],null,false,2839240904)}):_vm._e(),(_vm.checkPermissionForMenuHeader(_vm.permissions.payroll, _vm.getUserPermission))?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v("Payroll")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),(_vm.checkPermissionForMenu('access_to_salary_heads', _vm.getUserPermission))?_c('router-link',{attrs:{"to":{ name: 'payroll.salary.heads' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-list"}),_c('span',{staticClass:"menu-text"},[_vm._v("Salary Heads")])])])]}}],null,false,3156884651)}):_vm._e(),(_vm.checkPermissionForMenuHeader(_vm.permissions.settings, _vm.getUserPermission))?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v("Settings")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),(_vm.checkPermissionForMenu('access_to_settings', _vm.getUserPermission))?_c('router-link',{attrs:{"to":{ name: 'settings' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-settings"}),_c('span',{staticClass:"menu-text"},[_vm._v("Settings")])])])]}}],null,false,3639779558)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }